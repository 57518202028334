// extracted by mini-css-extract-plugin
export var cAtf = "styles-module--cAtf--TYplM";
export var cBorderRadius = "styles-module--cBorderRadius--mSsHn";
export var cHeading = "styles-module--cHeading--+961c";
export var cHeadingWrapper = "styles-module--cHeadingWrapper--ZmQEs";
export var cImage1 = "styles-module--cImage1--QnUF1";
export var cImage2 = "styles-module--cImage2--9Ah94";
export var cImage3 = "styles-module--cImage3--qr21+";
export var cImage4 = "styles-module--cImage4--Yd7S9";
export var cImage5 = "styles-module--cImage5--lSVOt";
export var cImage6 = "styles-module--cImage6--Ww8bj";
export var cImg = "styles-module--cImg--J+Li9";
export var cIndicator = "styles-module--cIndicator--vkmaD";
export var cLink = "styles-module--cLink--Dxr0U";
export var cLinkWrapper = "styles-module--cLinkWrapper--+dcZC";